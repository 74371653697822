import { Box, Grid } from "@mui/material";
import ContactUs from "../components/contact-us/ContactUs";
import contactUsImg from "../assets/contactUs.png";
const ContactUsPage = () => {
  return (
    <>
    <Box
        sx={{
          backgroundImage: `url(${contactUsImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          // width: "vw",
          // height: "auto",
          backgroundColor: "black",
          color: "white",
          
          minHeight: "100vh",
          // cursor: `url(${Cursor})`,
        }}
      >
        <Grid container display="flex" justifyContent="center">
          <Grid
            item
            xs={10}
            display="flex"
            justifyContent="center"
            sx={{ mt: 2 }}
            // border={"1px solid red"}
          >
            <ContactUs />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ContactUsPage;
