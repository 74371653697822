import { Box, Typography } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <>
      <Box component={"div"} sx={{ maxWidth: "768px" }}>
        <Typography variant={"h3"} textAlign={"center"}>
          Privacy Policy
        </Typography>
        <Typography textAlign={"center"} mb={2.5}>
          Blueprint designs
        </Typography>
        <hr />
        <p>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You respond to
          our advertisements and tells You about Your privacy rights and how the
          law protects You. We use Your Personal Data to contact and support
          you, as well as to provide and improve the Service. By using the
          Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>
        <Typography variant={"h4"} textAlign={"center"} mt={5}>
          Interpretation and Definitions
        </Typography>
        <Typography variant="h5" mt={2.25}>
          Interpretation
        </Typography>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
        <Typography variant="h5">Definitions</Typography>
        <p>For the purposes of this Privacy Policy:</p>
        <ul>
          <li>
            <strong>Company </strong>(referred to as either "the Company", "We",
            "Us" or "Our" in this Agreement) refers to{" "}
            <span id="company-name">Blueprint designs</span>.
          </li>
          <li>
            <strong>Device </strong>means any device that can access the Service
            such as a computer, a cellphone or a digital tablet.
          </li>
          <li>
            <strong>Personal Data</strong> is any information that relates to an
            identified or identifiable individual.
          </li>
          <li>
            <strong>Service </strong>refers to the advertisement, website, or
            application.
          </li>
          <li>
            <strong>Service Provider</strong> means any natural or legal person
            who processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used.
          </li>
          <li>
            <strong>Usage Data</strong> refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </li>
          <li>
            <strong>You </strong>means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </li>
        </ul>
        <p></p>
        <Typography variant="h4" textAlign={"center"} mt={5}>
          Collecting and Using Your Personal Data
        </Typography>
        <Typography variant="h5" mt={2.25}>
          Types of Data Collected
        </Typography>
        <Typography variant="h6">Personal Data</Typography>
        <p>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <ul>
          <li>First name and last name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Location details such as your address, city, or country</li>
          <li>
            Any other data such as personal preferences, requirements, or
            comments
          </li>
        </ul>
        <p></p>
        <Typography variant="h5">Use of Your Personal Data</Typography>
        <p>The Company may use Personal Data for the following purposes:</p>
        <ul>
          <li>
            <strong>To provide and maintain our Service</strong>, including to
            monitor the usage of our Service.
          </li>
          <li>
            <strong>To manage Your Account:</strong> to manage Your registration
            as a user of the Service. The Personal Data You provide can give You
            access to different functionalities of the Service that are
            available to You as a registered user.
          </li>
          <li>
            <strong>For the performance of a contract:</strong> the development,
            compliance and undertaking of the purchase contract for the
            products, items or services You have purchased or of any other
            contract with Us through the Service.
          </li>
          <li>
            <strong>To contact You: </strong>To contact You by email, telephone
            calls, SMS, or other equivalent forms of electronic communication,
            such as a mobile application's push notifications regarding updates
            or informative communications related to the functionalities,
            products or contracted services, including the security updates,
            when necessary or reasonable for their implementation.
          </li>
          <li>
            <strong>To provide You </strong>with news, special offers and
            general information about other goods, services and events which we
            offer that are similar to those that you have already purchased or
            enquired about unless You have opted not to receive such
            information.
          </li>
          <li>
            <strong>To manage Your requests:</strong> To attend and manage Your
            requests to Us.
          </li>
          <li>
            <strong>For business transfers: </strong>We may use Your information
            to evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of Our assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding, in which Personal
            Data held by Us about our Service users is among the assets
            transferred.
          </li>
          <li>
            <strong>For other purposes: </strong>We may use Your information for
            other purposes, such as data analysis, identifying usage trends,
            determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Service, products, services, marketing and
            your experience.
          </li>
        </ul>
        <p></p>
        <p>
          We may share Your personal information in the following situations:
        </p>
        <ul>
          <li>
            <strong>With Service Providers: </strong>We may share Your personal
            information with Service Providers to monitor and analyze the use of
            our Service, to contact You.
          </li>
          <li>
            <strong>For business transfers: </strong>We may share or transfer
            Your personal information in connection with, or during negotiations
            of, any merger, sale of Company assets, financing, or acquisition of
            all or a portion of Our business to another company.
          </li>
          <li>
            <strong>With Affiliates: We </strong>may share Your information with
            Our affiliates, in which case we will require those affiliates to
            honor this Privacy Policy. Affiliates include Our parent company and
            any other subsidiaries, joint venture partners or other companies
            that We control or that are under common control with Us.
          </li>
          <li>
            <strong>With business partners: </strong>We may share Your
            information with Our business partners to offer You certain
            products, services or promotions.
          </li>
          <li>
            <strong>With other users: </strong>when You share personal
            information or otherwise interact in the public areas with other
            users, such information may be viewed by all users and may be
            publicly distributed outside.
          </li>
          <li>
            <strong>With Your consent: </strong>We may disclose Your personal
            information for any other purpose with Your consent.
          </li>
        </ul>
        <p></p>
        <Typography variant="h5">Retention of Your Personal Data</Typography>
        <p>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </p>
        <p>
          The Company will also retain Usage Data for internal analysis
          purposes.
        </p>
        <Typography variant="h5">Transfer of Your Personal Data</Typography>
        <p>
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
          <br />
          <br />
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
          <br />
          <br />
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </p>
        <Typography variant="h5">Disclosure of Your Personal Data</Typography>
        <Typography variant="h6">Law enforcement</Typography>
        <p>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </p>
        <p></p>
        <Typography variant="h6">Other legal requirements</Typography>
        <p>
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </p>
        <ul>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            Protect the personal safety of Users of the Service or the public
          </li>
          <li>Protect against legal liability</li>
        </ul>
        <p></p>
        <Typography variant="h5">Security of Your Personal Data</Typography>
        <p>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </p>
        <Typography variant="h4" textAlign={"center"} mt={5}>
          Links to Other Websites
        </Typography>
        <p>
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
          <br />
          <br /> We have no control over and assume no responsibility for the
          content, privacy policies or practices of any third party sites or
          services.
        </p>
        <Typography variant="h4" textAlign={"center"} mt={5}>
          Changes to this Privacy Policy
        </Typography>
        <p>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
          <br />
          <br />
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
          <br />
          <br />
          This Privacy Policy was last updated on{" "}
          <span id="gen-at">2023/04/08 09:37:41</span>
        </p>
        <Typography variant="h4" textAlign={"center"} mt={5}>
          Contact Us
        </Typography>
        If you have any questions about this Privacy Policy, You can contact us
        via email at <span id="email">info@blueprintdesigns.nl</span>
        <hr />
        {/* <p class="text-secondary text-center my-4">
          ⚙️ &nbsp; Crafted using Privyr's{" "}
          <a href="https://www.privyr.com/tools/privacy-policy-generator-for-lead-ads">
            Privacy Policy Generator
          </a>
        </p> */}
      </Box>
    </>
  );
};

export default PrivacyPolicy;
