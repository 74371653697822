import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import BGImg from "../assets/background-projects.jpg";
import TeamDescription from "../components/team-description/TeamDescription";
import TeamAvatars from "../components/team-avatars/TeamAvatars";
import { Arrow } from "../components/arrow/Arrow";
// import Brands from "../components/brands/Brands";
import TeamPage1 from "../assets/TeamPage1.png";

const TeamPage = () => {
  let navigate = useNavigate();
  const scrollRef = useRef(0);
  const [wheel, setWheel] = useState(-1);
  const [rotate, setRotate] = useState(-45);

  const handleMouseEnter = () => {
    setRotate(0);
  };

  const handleMouseLeave = () => {
    setRotate(-45);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollDown =
        window.pageYOffset || document.documentElement.scrollTop;

      if (scrollDown > scrollRef.current) {
        // console.log('User has scrolled down the page');
        setWheel(99);
      }
      scrollRef.current = scrollTop;
      // const windowHeight = window.innerHeight;
      // const pageHeight = document.body.scrollHeight;

      if (scrollTop === 0) {
        // console.log('User is at the top of the page');
        setWheel(-1);
      }

      // if (scrollTop + windowHeight >= pageHeight) {
      //   console.log('User is at the bottom of the page');
      // }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${BGImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          // width: "vw",
          // height: "auto",
          backgroundColor: "black",
          color: "white",
          minHeight: "100vh",
          // cursor: `url(${Cursor})`,
        }}
      >
        <Grid container display="flex" justifyContent="center">
          <Grid item xs={12} display="flex" justifyContent="center">
            <Navbar wheel={wheel} />
          </Grid>
          <Grid
            item
            xs={10}
            display="flex"
            justifyContent="center"
            sx={{ mt: 20 }}
          >
            <Typography
              textAlign={"center"}
              fontSize={{ xs: "26px", sm: "28px", md: "48px", lg: "54px" }}
            >
              Professionaliteit, passie en klantgericht. Dat zijn wij.
            </Typography>
          </Grid>
          <Grid
            item
            xs={10}
            display="flex"
            justifyContent="center"
            sx={{ mt: 20 }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
                // border="1px solid white"
              >
                <TeamDescription />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
                // border="1px solid white"
              >
                <Box
                  width={"75%"}
                  height={"auto"}
                  component={"img"}
                  src={TeamPage1}
                  alt="team"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={10}
            display="flex"
            justifyContent="center"
            sx={{ mt: 20 }}
          >
            <TeamAvatars />
          </Grid>
          <Grid
            item
            xs={10}
            display="flex"
            justifyContent="center"
            sx={{ mt: 10 }}
          >
            <Typography variant="h2" textAlign={"center"}>
              Wil je weten hoe wij bedrijven helpen?
            </Typography>
          </Grid>
          <Grid
            item
            xs={10}
            display="flex"
            justifyContent="center"
            sx={{ mt: 5 }}
          >
            <Button
              onClick={() => navigate("/contact")}
              color="secondary"
              variant="contained"
              size="small"
              sx={{
                // display: { xs: "none", sm: "flex" },
                borderRadius: "1.25em",
                textTransform: "none",
                px: 3,
                ml: { xs: 1, md: 5 },
                backgroundColor: "#2c32b3",
                "&:hover": {
                  backgroundColor: "#3138cb",
                },
                // width: {
                //   xs: "0.4em",
                //   sm: "0.6em",
                //   md: "0.8em",
                //   lg: "auto",
                // },
                height: { xs: "4.5vh", md: "5.5vh" },
              }}
              endIcon={<Arrow rotate={rotate} variant={"arrow"} />}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Typography
                fontWeight={"bold"}
                sx={{ fontSize: { xs: "9px", sm: "12px", md: "15px" } }}
              >
                Neem Contact Op
              </Typography>
            </Button>
          </Grid>

          <Grid
            item
            xs={10}
            display="flex"
            justifyContent="center"
            sx={{ mt: 10 }}
          >
            <Typography variant="h2" textAlign={"center"}>
              Relaties gebaseerd op vertrouwen
            </Typography>
          </Grid>
          {/* <Grid
            item
            xs={10}
            display="flex"
            justifyContent="center"
            sx={{ mt: 10 }}
          >
            <Brands />
          </Grid> */}
          <Grid
            item
            xs={10}
            display="flex"
            justifyContent="center"
            sx={{ mt: 20 }}
          >
            <Footer />
          </Grid>
          <Grid
            item
            xs={10}
            display="flex"
            justifyContent="center"
            sx={{ mt: 10 }}
          >
            <></>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TeamPage;
