import { Button, Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useState, Fragment } from "react";
import { Instagram, Facebook, LinkedIn } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { Arrow } from "../arrow/Arrow";
import { AnimationHeading } from "../animation/Animation";
import Logo from "../../assets/1080p transparent.gif";

const items = [
  { id: 0, text: "Home" },
  { id: 1, text: "Lopende Projecten" },
  { id: 3, text: "Diensten" },
  { id: 2, text: "Over Ons" },
  { id: 4, text: "Blog" },
  { id: 5, text: "Contact" },
];

const socialItems = [
  {
    id: 1,
    component: (
      <a
        href={"https://www.instagram.com/blueprintdesigns.nl"}
        style={{ color: "inherit" }}
      >
        <Instagram sx={{ fontSize: { xs: 26, sm: 27, md: 28, lg: 30 } }} />
      </a>
    ),
  },
  {
    id: 2,
    component: (
      <a
        href={"https://www.facebook.com/profile.php?id=100089482491808"}
        style={{ color: "inherit" }}
      >
        <Facebook
          sx={{ fontSize: { xs: 26, sm: 27, md: 28, lg: 30 } }}
          href={""}
        />
      </a>
    ),
  },
  {
    id: 3,
    component: (
      <a
        href={"https://www.linkedin.com/company/blueprintdesigns/ "}
        style={{ color: "inherit" }}
      >
        <LinkedIn
          sx={{ fontSize: { xs: 26, sm: 27, md: 28, lg: 30 } }}
          href={""}
        />
      </a>
    ),
  },
];

const FooterItems = ({ text }) => {
  const [rotate, setRotate] = useState(-45);

  let navigate = useNavigate();

  const handleMouseEnter = () => {
    setRotate(0);
  };

  const handleMouseLeave = () => {
    setRotate(-45);
  };
  return (
    <>
      <motion.div
        layout
        whileInView={{
          x: 0,
          borderRadius: 2,
          borderBottom: "1px solid rgb(255,255,255,0.1)",
          opacity: 1,
        }}
        whileHover={{
          borderBottom: `1px solid rgb(255,255,255,1)`,
          scale: 1.04,
        }}
        animate={{
          x: -50,
          borderBottom: "1px solid rgb(255,255,255,0.1)",
          opacity: 0.1,
        }}
        transition={{
          // delay: 0.5,
          // duration: 0.5,
          // stiffness: 5,
          // damping: 50,
          // delay: 0.2,
          x: { duration: 0.5 },
          default: { ease: "linear" },
        }}
      >
        <Grid
          container
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          borderRadius={"1rem"}
          // borderBottom={"1px solid white"}
          alignItems="center"
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            // console.log(text);
            navigate(`/${text.toLowerCase()}`);
          }}
        >
          <Grid
            item
            xs={6}
            md={6}
            display={"flex"}
            justifyContent={"start"}
            // pl={3}
          >
            <Typography
              fontSize={{
                xs: "0.85rem",
                sm: "0.85rem",
                md: "0.875rem",
                lg: "1rem",
              }}
            >
              {text}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            display={"flex"}
            justifyContent={"end"}
            // pr={3}
          >
            <Arrow
              rotate={rotate}
              fontSize={{
                xs: "1.2rem",
                sm: "1.4rem",
                md: "1.6rem",
                lg: "1.8rem",
              }}
            />
          </Grid>
        </Grid>
      </motion.div>
    </>
  );
};

const Footer = () => {
  let navigate = useNavigate();
  const [rotate, setRotate] = useState(-45);

  const handleNavigate = () => {
    navigate("/contact");
  };

  const handleMouseEnter = () => {
    setRotate(0);
  };

  const handleMouseLeave = () => {
    setRotate(-45);
  };

  return (
    <>
      <AnimationHeading>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              component="div"
              sx={{
                fontSize: { xs: "30px", sm: "40px", md: "58px", lg: "68px" },
                // mb: -2,
              }}
            >
              Heb een idee?
            </Typography>
            <Typography
              component="div"
              sx={{
                fontSize: { xs: "30px", sm: "40px", md: "58px", lg: "68px" },
              }}
            >
              Laten we het tot leven brengen
              <Button
                onClick={handleNavigate}
                color="secondary"
                variant="contained"
                size="small"
                sx={{
                  // display: { xs: "none", sm: "flex" },
                  borderRadius: "1.25em",
                  textTransform: "none",
                  px: 3,
                  ml: { xs: 1, md: 5 },
                  backgroundColor: "#2c32b3",
                  "&:hover": {
                    backgroundColor: "#3138cb",
                  },
                  // width: {
                  //   xs: "0.4em",
                  //   sm: "0.6em",
                  //   md: "0.8em",
                  //   lg: "auto",
                  // },
                  height: { xs: "4.5vh", md: "5.5vh" },
                }}
                endIcon={<Arrow rotate={rotate} variant={"arrow"} />}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Typography
                  fontWeight={"bold"}
                  sx={{ fontSize: { xs: "9px", sm: "12px", md: "15px" } }}
                >
                  Neem Contact Op
                </Typography>
              </Button>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            // border={"1px solid red"}
            mb={{ xs: -2, md: 0 }}
            mt={{ xs: 1.5, md: 0 }}
          >
            {/* <motion.div
              whileInView={{ rotate: 180, duration: 100 }}
              animate={{ rotate: 360, duration: 100 }}
            > */}
            <img src={Logo} alt="3D Logo" style={{ maxWidth: "35vw" }} />
            {/* </motion.div> */}
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            mt={4}

            // display={"flex"}
            // justifyContent={"center"}
            // alignItems={"center"}
          >
            {items.map(({ id, text }) => (
              <FooterItems key={id} text={text} />
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            display={"flex"}
            justifyContent={"center"}
            mt={4}
          >
            <Grid container display={"flex"} justifyContent={"center"}>
              <Grid
                item
                xs={12}
                mb={2.5}
                display={"flex"}
                justifyContent={"space-evenly"}
              >
                {socialItems.map(({ id, component }) => (
                  <Fragment key={id}>{component}</Fragment>
                ))}
              </Grid>
              <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      component={"p"}
                      align={"center"}
                      sx={{
                        fontSize: {
                          xs: 13,
                          sm: 14,
                          md: 16,
                          lg: 17,
                        },
                      }}
                    >
                      Street: Weena 505,
                    </Typography>
                    <Typography
                      component={"p"}
                      align={"center"}
                      sx={{
                        fontSize: {
                          xs: 13,
                          sm: 14,
                          md: 16,
                          lg: 17,
                        },
                      }}
                    >
                      Post Code: 3013 AL,
                    </Typography>
                    <Typography
                      component={"p"}
                      align={"center"}
                      sx={{
                        fontSize: {
                          xs: 13,
                          sm: 14,
                          md: 16,
                          lg: 17,
                        },
                      }}
                    >
                      City: Rotterdam, Netherlands
                    </Typography>
                  </Grid>
                  <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                    <Typography
                      component={"p"}
                      align={"center"}
                      // mt={{ xs: 0, md: -2 }}
                      sx={{
                        fontSize: {
                          xs: 13,
                          sm: 14,
                          md: 16,
                          lg: 17,
                        },
                      }}
                    >
                      Email:
                    </Typography>
                    <Typography
                      component={"a"}
                      href="mailto: info@blueprintdesigns.nl"
                      ml={0.75}
                      // mt={{ xs: 0, md: -2 }}
                      sx={{
                        textDecoration: "none",
                        color: "white",
                        "&:hover": { textDecoration: "underline" },
                        fontSize: {
                          xs: 13,
                          sm: 14,
                          md: 16,
                          lg: 17,
                        },
                      }}
                    >
                      info@blueprintdesigns.nl
                    </Typography>
                  </Grid>
                  <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                    <Typography
                      component={"p"}
                      align={"center"}
                      // mt={{ xs: 0, md: -2 }}
                      sx={{
                        fontSize: {
                          xs: 13,
                          sm: 14,
                          md: 16,
                          lg: 17,
                        },
                      }}
                    >
                      Phone:
                    </Typography>
                    <Typography
                      component={"a"}
                      href="tel: 085-800-0082"
                      ml={0.75}
                      // mt={{ xs: 0, md: -2 }}
                      sx={{
                        fontSize: {
                          xs: 13,
                          sm: 14,
                          md: 16,
                          lg: 17,
                        },
                        textDecoration: "none",
                        color: "white",
                        "&:hover": { textDecoration: "underline" },
                      }}
                    >
                      085 800 0082
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                mt={2}
              >
                <Grid container display={"flex"} justifyContent={"center"}>
                  <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                    <Typography
                      component={"p"}
                      variant={"subtitle2"}
                      color={"grey"}
                      sx={{
                        fontSize: {
                          xs: 13,
                          sm: 14,
                          md: 16,
                          lg: 17,
                        },
                      }}
                    >
                      Opgericht in 2023
                    </Typography>
                  </Grid>
                  <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                    <Typography
                      component={"p"}
                      variant={"subtitle2"}
                      color={"grey"}
                      mt={{ xs: 0, md: -0.5 }}
                      onClick={() => {
                        navigate("/privacy policy");
                      }}
                      sx={{
                        fontSize: {
                          xs: 13,
                          sm: 14,
                          md: 16,
                          lg: 17,
                        },
                        cursor: "pointer",
                        "&:hover": { textDecoration: "underline" },
                      }}
                    >
                      Privacy Policy
                    </Typography>
                  </Grid>
                  <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                    <Typography
                      component={"p"}
                      variant={"subtitle2"}
                      color={"grey"}
                      mt={{ xs: 0, md: -0.5 }}
                      sx={{
                        fontSize: {
                          xs: 13,
                          sm: 14,
                          md: 16,
                          lg: 17,
                        },
                      }}
                    >
                      © {new Date().getFullYear()}. BluePrintDesigns.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AnimationHeading>
    </>
  );
};

export default Footer;
