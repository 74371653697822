import "./Blog.css";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BlogContext } from "../../context/blog/BlogContext";
import axios from "../../utils/services/axios";

const RelatedArticle = ({ id, img, content }) => {
  const maxLength = 150;
  if (content.length > maxLength) {
    content = content.substr(0, maxLength) + " [...]";
  }

  let navigate = useNavigate();

  return (
    <>
      <Grid
        container
        spacing={2}
        mb={2}
        sx={{
          cursor: "pointer",
        }}
        onClick={() => {
          navigate(`/blog/${id}`);
        }}
      >
        <Grid item xs={6} display="flex" justifyContent={"center"}>
          <Box
            component={"img"}
            src={img}
            alt="related article"
            width={"100%"}
            height={"18vh"}
            borderRadius={2}
          />
        </Grid>
        <Grid item xs={6} display="flex" alignItems={"center"}>
          <Typography variant="h6" color={"lightgray"}>
            {content}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

const BlogDescription = ({ type, date }) => {
  const dateObj = new Date(date);

  const month = dateObj.getMonth() + 1; //months from 1-12
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();

  const newDate = day + "." + month + "." + year;

  return (
    <>
      <Grid container justifyContent={"center"}>
        <Grid
          item
          xs={2}
          display="flex"
          alignItems={"center"}
          // border="1px solid red"
          justifyContent={"center"}
        >
          <Typography>{type}</Typography>
        </Grid>
        <Grid
          item
          xs={0.5}
          display="flex"
          justifyContent={"center"}
          alignItems={"center"}
          // border="1px solid red"
        >
          <Typography>▪</Typography>
        </Grid>
        <Grid
          item
          xs={2}
          display="flex"
          alignItems={"center"}
          // border="1px solid red"
          justifyContent={"center"}
        >
          <Typography>{newDate}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

const BlogItem = ({ img, type, title, date, content }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} display={"flex"} justifyContent={"center"}>
          <Box
            component={"div"}
            sx={{
              position: "relative",
              overflow: "hidden",
            }}
            width={"100%"}
            height={"55vh"}
            borderRadius={2}
          >
            <Box
              component={"div"}
              width={"100%"}
              height={"100%"}
              // className="background-image"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                // width: "100%",
                // height: "100%",
                transition: "all .8s ease-in-out",
                // zIndex: -1,
                backgroundImage: `url(${img})`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                // "&:hover": {
                //   scale: "1.1",
                // },
                // transform: hovered ? "scale(1.1)" : "scale(1)",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} display={"flex"} justifyContent={"center"} mt={3}>
          <BlogDescription type={type} date={date} />
        </Grid>
        <Grid item xs={12} mt={2.5}>
          {/* <Typography variant="h3">{title}</Typography> */}
          <div
            // display="block"
            className=""
            border={"1px solid red"}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <div className="credit-anc">
            <a href="https://designindc.com/">Credit To Design in DC</a>
          </div>

          {/* </div> */}
          {/* {content} */}
        </Grid>
      </Grid>
    </>
  );
};

const Blog = () => {
  // const relatedArticles = [
  //   {
  //     id: 1,
  //     img: "https://images.pexels.com/photos/204112/pexels-photo-204112.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  //     content:
  //       "GPT-4: A Glimpse into the AI Revolution – The Good, the Bad, and the Uncertain",
  //   },
  //   {
  //     id: 2,
  //     img: "https://images.pexels.com/photos/204112/pexels-photo-204112.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  //     content:
  //       "GPT-4: A Glimpse into the AI Revolution – The Good, the Bad, and the Uncertain",
  //   },
  //   {
  //     id: 3,
  //     img: "https://images.pexels.com/photos/204112/pexels-photo-204112.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  //     content:
  //       "GPT-4: A Glimpse into the AI Revolution – The Good, the Bad, and the Uncertain",
  //   },
  //   {
  //     id: 4,
  //     img: "https://images.pexels.com/photos/204112/pexels-photo-204112.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  //     content:
  //       "GPT-4: A Glimpse into the AI Revolution – The Good, the Bad, and the Uncertain",
  //   },
  //   {
  //     id: 5,
  //     img: "https://images.pexels.com/photos/204112/pexels-photo-204112.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  //     content:
  //       "GPT-4: A Glimpse into the AI Revolution – The Good, the Bad, and the Uncertain",
  //   },
  // ];

  let params = useParams();
  const { blogPosts, findBlogPost } = useContext(BlogContext);
  const [article, setArticle] = useState({});
  const [relatedArticles, setRelatedArticles] = useState([]);

  useEffect(() => {
    console.log(params.id);
    const newArticle = findBlogPost(params.id);
    console.log(newArticle);

    setArticle(newArticle);
  }, [blogPosts, params, findBlogPost]);

  useEffect(() => {
    const newRelatedArticles = async () => {
      try {
        const response = await axios.get("blog/related");
        // console.log(response.data);
        setRelatedArticles(response.data.message.randomArticles);
      } catch (error) {
        console.log(error);
      }
    };

    newRelatedArticles();
  }, [params]);

  return (
    <>
      <Grid container display={"flex"} justifyContent={"center"}>
        <Grid
          item
          md={7}
          display={"flex"}
          justifyContent={"center"}
          position={"relative"}
        >
          <BlogItem
            img={article?.image_url}
            type={article?.topic}
            title={article?.title}
            date={article?.date}
            content={article?.content_with_tag}
            // content={article?.content}
          />
        </Grid>
        <Grid
          item
          md={0.5}
          display={{ xs: "none", md: "flex" }}
          justifyContent={"center"}
        >
          <Divider
            orientation="vertical"
            light={false}
            // flexItem
            sx={{ borderColor: "rgb(255, 255, 255, 0.5)" }}
          />
        </Grid>
        <Grid
          item
          md={4.5}
          display={{ xs: "none", md: "flex" }}
          justifyContent={"center"}
          // border="1px solid blue"
          position={"sticky"}
          // height={"10px"}
          // right={0}
        >
          <Grid
            container
            height="10px"
            display={"flex"}
            justifyContent={"center"}
          >
            <Grid item xs={12}>
              <Typography variant="h6">Related Articles</Typography>
            </Grid>
            <Grid item xs={12} mt={2}>
              {relatedArticles?.map(({ _id, image_url, title }) => (
                <RelatedArticle
                  key={_id}
                  id={_id}
                  img={image_url}
                  content={title}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Blog;
