import { Divider, Grid, Typography } from "@mui/material";
import { AllInclusive } from "@mui/icons-material";
import { Fragment } from "react";

const TeamDescriptionItems = ({ text, description, icon }) => {
  return (
    <Grid container py={5}>
      <Grid item xs={12}>
        <Typography variant="h4">{text}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{description}</Typography>
      </Grid>
      <Grid
        item
        xs={4}
        display="flex"
        justifyContent="center"
        alignItems={"center"}
        // border={"1px solid red"}
      >
        {icon}
      </Grid>
    </Grid>
  );
};

const TeamDescription = () => {
  const items = [
    {
      id: 0,
      text: "20+ Medewerkers",
      description:
        "Het team van BlueprintDesigns staat vol met 20+ passievolle medewerkers op het gebied van Webdevelopment en- design, Appdevelopment en- design, Marketing en branding.",
      icon: (
        <AllInclusive
          sx={{
            width: "2em",
            height: "2em",
          }}
        />
      ),
    },
    {
      id: 1,
      text: "10+ Projecten",
      description:
        "Ook hebben wij als team 10 grote projecten voltooid afgerond, alwelniet over de honderd mochten de kleine projecten meetellen. Met bakkenvol ervaring staat Blueprint paraat om ook uw digitale dromen waar te maken.",
      icon: (
        <AllInclusive
          sx={{
            width: "2em",
            height: "2em",
          }}
        />
      ),
    },
    {
      id: 2,
      text: "5+ Jaar ervaring",
      description:
        "Blueprint designs is officieel begonnen in 2023 als team, maar ook hebben wij al 5+ jaar ervaring in de digitale wereld. Wij houden van een uitdaging en niets is te groot!",
      icon: (
        <AllInclusive
          sx={{
            width: "2em",
            height: "2em",
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          {items.map(({ id, text, description, icon }) => {
            return (
              <Fragment key={id}>
                <TeamDescriptionItems
                  text={text}
                  description={description}
                  icon={icon}
                />
                <Divider sx={{ backgroundColor: "rgb(255, 255, 255, 0.3)" }} />
              </Fragment>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default TeamDescription;
