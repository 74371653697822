import { Box, Grid } from "@mui/material";
import PrivacyPolicy from "../components/privacy-policy/PrivacyPolicy";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";

const PrivacyPolicyPage = () => {
  const [wheel, setWheel] = useState(-1);

  const scrollRef = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollDown =
        window.pageYOffset || document.documentElement.scrollTop;

      if (scrollDown > scrollRef.current) {
        // console.log('User has scrolled down the page');
        setWheel(99);
      }
      scrollRef.current = scrollTop;
      // const windowHeight = window.innerHeight;
      // const pageHeight = document.body.scrollHeight;

      if (scrollTop === 0) {
        // console.log('User is at the top of the page');
        setWheel(-1);
      }

      // if (scrollTop + windowHeight >= pageHeight) {
      //   console.log('User is at the bottom of the page');
      // }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "black",
        color: "white",
        minHeight: "100vh",
      }}
    >
      <Grid container display="flex" justifyContent="center">
        <Grid item xs={12} display="flex" justifyContent="center">
          <Navbar wheel={wheel} />
        </Grid>
        <Grid
          item
          xs={10}
          display="flex"
          justifyContent="center"
          sx={{ mt: 20 }}
        >
          <PrivacyPolicy />
        </Grid>
        <Grid
          item
          xs={10}
          display="flex"
          justifyContent="center"
          sx={{ mt: 20 }}
        >
          <Footer />
        </Grid>
        <Grid
          item
          xs={10}
          display="flex"
          justifyContent="center"
          sx={{ mt: 10 }}
        >
          <></>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PrivacyPolicyPage;
