import { createContext, useState, useEffect } from "react";
import axios from "../../utils/services/axios";

const findBlogPost = (blogPosts, id) => {
  console.log("findBlogPost");
  return blogPosts.find((blogPost) => blogPost._id === id);
};

export const BlogContext = createContext({
  blogPosts: [],
  setBlogPosts: () => {},
  currentPage: 1,
  setCurrentPage: () => {},
  totalPages: 10,
  setTotalPages: () => {},
  loading: false,
  setLoading: () => {},
});

export const BlogProvider = ({ children }) => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(`blog/?page=${currentPage}&limit=5`);
        const { message } = data;
        const { articles, totalPages } = message;

        setBlogPosts([...articles]);
        setTotalPages(totalPages);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentPage]);

  const value = {
    blogPosts,
    setBlogPosts,
    currentPage,
    setCurrentPage,
    totalPages,
    setTotalPages,
    loading,
    setLoading,
    findBlogPost: (id) => findBlogPost(blogPosts, id),
  };

  return <BlogContext.Provider value={value}>{children}</BlogContext.Provider>;
};
