import { InlineWidget } from "react-calendly";

const Calendly = () => {
  return (
    <>
      <InlineWidget url="https://calendly.com/blueprintdesigns/blueprint-1-op-1-meeting" />
    </>
  );
};

export default Calendly;
