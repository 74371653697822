import {
  Button,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { motion } from "framer-motion";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import instance from "../../utils/services/axios";

import { Arrow } from "../arrow/Arrow";
import CustomSnackbar from "../snackbar/Snackbar";
import contactUsImg from "../../assets/contactUs.png";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#2c32b3",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2c32b3",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#fff",
    },
    "&:hover fieldset": {
      borderColor: "#2c32b3",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2c32b3",
    },
  },
});

const CustomButton = ({ label, handleChange, value, emptyError }) => {
  return (
    <>
      <Grid item xs={12} sm={6.01}>
        <CssTextField
          required={label === "naam" || label === "email"}
          sx={{ mb: 1.25 }}
          fullWidth
          size="small"
          label={label.charAt(0).toUpperCase() + label.slice(1)}
          id={label.toLowerCase()}
          value={value[label.toLowerCase()]}
          InputLabelProps={{
            style: { color: "white" },
          }}
          InputProps={{
            style: { color: "white" },
          }}
          onChange={handleChange}
          error={
            emptyError &&
            !value[label] &&
            (label === "naam" || label === "email")
          }
          // helperText={
          //   !value[label] &&
          //   (label === "naam" || label === "email") &&
          //   `${label.charAt(0).toUpperCase() + label.slice(1)} is required.`
          // }
        />
      </Grid>
    </>
  );
};

const ContactUs = () => {
  let navigate = useNavigate();
  const [form, setForm] = useState({
    naam: "",
    email: "",
    phone: "",
    organisatie: "",
  });

  const [loading, setLoading] = useState(false);
  const [emptyError, setEmptyError] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [rotate, setRotate] = useState(-45);

  const handleNavigate = () => {
    navigate(-1);
  };

  const handleErrorEmail = () => {
    setMessage("Unable to send email!");
    setSeverity("error");
    setBackgroundColor("error");
    setOpen(true);
  };

  const handleSentEmail = () => {
    setMessage("Email sent successfully!");
    setSeverity("success");
    setBackgroundColor("#2c32b3");
    setOpen(true);
  };

  const handleChange = (event) => {
    setForm({ ...form, [event.target.id]: event.target.value });
  };

  const handleSubmit = async () => {
    if (!form.naam || !form.email) {
      setEmptyError(true);
      return;
    }

    try {
      setLoading(true);
      const data = {
        name: form.naam,
        email: form.email,
        phone: form?.phone,
        organization: form?.organisatie,
      };

      const response = await instance.post("/email", data);

      if (response.data.code !== 0) {
        setForm({
          naam: "",
          email: "",
          phone: "",
          organisatie: "",
        });
        throw new Error();
      }

      handleSentEmail();
      setEmptyError(false);

      setForm({
        naam: "",
        email: "",
        phone: "",
        organisatie: "",
      });
      setLoading(false);
      navigate("/intake");
    } catch (error) {
      handleErrorEmail();
      setLoading(false);
    }
  };

  const handleMouseEnter = () => {
    setRotate(0);
  };

  const handleMouseLeave = () => {
    setRotate(-45);
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"end"}
          //
        >
          <motion.div
            animate={{ rotate: -180 }}
            whileInView={{ rotate: 180 }}
            whileHover={{ rotate: 360 }}
            transition={{
              type: "spring",
              stiffness: 50,
            }}
          >
            <Close sx={{ fontSize: "4vw" }} onClick={handleNavigate} />
          </motion.div>
        </Grid>
        <Grid item xs={12} display={"flex"}  justifyContent={"start"} mt={10}>
          <Typography
            mt={3}
            component={"div"}
            fontSize={{ xs: 30, sm: 25, md: 40 }}
            fontWeight={"bold"}
          >
            Laten we praten!
          </Typography>
        </Grid>
        <Grid item xs={12} display={"flex"} justifyContent={"start"}>
          <Typography component={"div"} mb={1} fontSize={{ xs: 15, sm: 12.5, md: 20 }}>
            Vul het volgende in om uw project te starten
          </Typography>
        </Grid>
        <Grid
          item
          xs={7.5}
         
          display={"flex"}
          alignItems={"center"}
          // border={"1px solid red"}
        >
          <Grid item xs={12}>
            <Grid container>
              {Object.keys(form).map((item, index) => (
                <CustomButton
                  xs={12}
                  key={index}
                 
                  label={item}
                  value={form}
                  handleChange={handleChange}
                  emptyError={emptyError}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={4.5}
        
          display={"flex"}
          alignItems={"center"}
          // border={"1px solid red"}
        >
          
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={loading}
            variant="contained"
            size="small"
            onClick={handleSubmit}
            sx={{
              // display: { xs: "none", sm: "flex" },
              borderRadius: "1.25em",
              textTransform: "none",
              px: 3,
              backgroundColor: "#2c32b3",
              "&:hover": {
                backgroundColor: "#3138cb",
              },
              "&.Mui-disabled": {
                backgroundColor: "#2c32b380",
                color: "#ffffff87",
                // add any other styles here as needed
              },
              // width: {
              //   xs: "0.4em",
              //   sm: "0.6em",
              //   md: "0.8em",
              //   lg: "auto",
              // },
              height: "3.25em",
            }}
            endIcon={
              !loading ? (
                <Arrow
                  rotate={rotate}
                  variant={"arrow"}
                  fontSize={{ xs: "17px", sm: "20px" }}
                />
              ) : (
                <CircularProgress size={20} sx={{ color: "#ffffff87" }} />
              )
            }
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Typography
              fontWeight={"bold"}
              sx={{ fontSize: { xs: "11.5px", sm: "12px", md: "15px" } }}
            >
              Doorgaan
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={open}
        setOpen={setOpen}
        message={message}
        severity={severity}
        backgroundColor={backgroundColor}
      />
    </>
  );
};

export default ContactUs;
