import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Circle } from "@mui/icons-material";

const TeamAvatarsItems = ({ name, position, img, video }) => {
  const [hover, setHover] = useState(false);

  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
        md={3}
        // backgroundColor={"white"}
        display="flex"
        alignItems={"center"}
      >
        <Grid
          container
          m={1}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
          border={
            hover
              ? "1px solid rgb(44, 50, 179, 1)"
              : "1px solid rgb(255, 255, 255, 0.3)"
          }
          sx={{
            scale: hover ? "1.05" : "1",
            transition: "all 0.3s ease-in-out",
          }}
        >
          <Grid item xs={6} p={2}>
            <Typography fontWeight={"bold"}>{name.toUpperCase()}</Typography>
          </Grid>
          <Grid item xs={6} p={2} display={"flex"} justifyContent={"end"}>
            <Typography fontWeight={"bold"}>
              {position.toUpperCase()}
            </Typography>

            <Circle
              sx={{
                ml: 1,
                width: "10%",
                color: "#9AFD01",
                // scale: hover ? "1.05" : "1",
                // transition: "all 0.3s ease-in-out",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            py={2}
            display={"flex"}
            justifyContent={"center"}
            // border={"1px solid red"}
          >
            <Box
              component={"img"}
              src={img}
              alt="avatar"
              width={"100%"}
              height={"auto"}
              display={hover ? "none" : "block"}
              // border={"1px solid white"}
            />
            <Box
              // border={"1px solid white"}
              component={"video"}
              src={video}
              alt="avatar"
              width={"100%"}
              height={"auto"}
              muted
              loop
              autoPlay
              playsInline
              preload="metadata"
              display={hover ? "block" : "none"}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const TeamAvatars = () => {
  const items = [
    {
      id: 0,
      name: "Anita",
      position: "Founder",
      img: "https://www.brandium.nl/wp-content/uploads/2022/04/Jamie.jpg",
      video:
        "https://www.brandium.nl/wp-content/uploads/2022/04/Jamie-brandium.mp4",
    },
    {
      id: 1,
      name: "Komaz",
      position: "Developer",
      img: "https://www.brandium.nl/wp-content/uploads/2022/04/Soufian.jpg",
      video:
        "https://www.brandium.nl/wp-content/uploads/2022/04/soufian-brandium.mp4",
    },
    {
      id: 2,
      name: "Frederik",
      position: "CTO",
      img: "https://www.brandium.nl/wp-content/uploads/2022/05/abraham-brandium.jpg",
      video: "https://www.brandium.nl/wp-content/uploads/2022/04/Abra.mp4",
    },
    {
      id: 3,
      name: "Jacob",
      position: "CEO",
      img: "https://www.brandium.nl/wp-content/uploads/2022/08/Koen_brandium.jpg",
      video:
        "https://www.brandium.nl/wp-content/uploads/2022/08/Koen_brandium.mp4",
    },
  ];

  return (
    <>
      <Grid container>
        {items.map(({ id, name, position, img, video }) => {
          return (
            <TeamAvatarsItems
              key={id}
              name={name}
              position={position}
              img={img}
              video={video}
            />
          );
        })}
      </Grid>
    </>
  );
};

export default TeamAvatars;
