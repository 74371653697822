import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect, useState, lazy,Suspense } from "react";
import HomePage from "../pages/HomePage";
import ContactUsPage from "../pages/ContactUsPage";
import ProjectPage from "../pages/ProjectsPage";
import ServicesPage from "../pages/ServicesPage";
import CalendlyPage from "../pages/CalendlyPage";
import MenuPage from "../pages/MenuPage";
import TeamPage from "../pages/TeamPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import BlogPage from "../pages/BlogPage";
import BlogPostPage from "../pages/BlogPostPage";
import { BlogProvider } from "../context/blog/BlogContext";
const Loading = lazy(() => import("../components/loading/Loading"));

const MainRoute = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 6500);
  }, []);

  return (
    <>
      <Router>
        <BlogProvider>
        <Suspense fallback={<div></div>}>
          <Routes>
            <Route
              path={"/"}
              index
              element={isVisible ? <HomePage /> : <Loading />}
            />
            <Route path="/home" element={<HomePage />} />
            <Route path="/menu" element={<MenuPage />} />
            <Route path="/contact" element={<ContactUsPage />} />
            <Route path="/intake" element={<CalendlyPage />} />
            <Route path="/lopende projecten" element={<ProjectPage />} />
            <Route path="/diensten" element={<ServicesPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/:id" element={<BlogPostPage />} />
            <Route path="/over ons" element={<TeamPage />} />
              <Route path="/privacy policy" element={<PrivacyPolicyPage />} />
            
            </Routes>
            </Suspense>
        </BlogProvider>
      </Router>
    </>
  );
};

export default MainRoute;
