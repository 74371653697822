import { useState, useContext } from "react";
import { BlogContext } from "../../context/blog/BlogContext";
import {
  Box,
  Grid,
  Pagination,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Logo from "../../assets/1080p transparent.gif";
import { useNavigate } from "react-router-dom";

const BlogMainSubItem = ({ title, content, type, date }) => {
  const maxLength = 300;
  if (content.length > maxLength) {
    content = content.substr(0, maxLength) + " [...]";
  }
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={9.5}
        display={{ xs: "flex", md: "block" }}
        justifyContent={"center"}
      >
        <Typography variant="subtitle2" color="lightgray">
          {type} ▪ {date}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={9.5}
        mt={1}
        display={{ xs: "flex", md: "block" }}
        justifyContent={"center"}
      >
        <Typography
          variant="h5"
          textAlign={{ xs: "center", md: "left" }}
          sx={{
            color: "#2c32b3",
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={9.5}
        mt={2.5}
        display={{ xs: "flex", md: "block" }}
        justifyContent={"center"}
      >
        <Typography textAlign={{ xs: "center", md: "left" }} color="lightgray">
          {content}
        </Typography>
      </Grid>
    </Grid>
  );
};

const BlogImage = ({ img, hovered, marginLeft, marginRight }) => {
  return (
    <>
      {/* <Grid
        item
        xs={8}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        // height={"50%"}
        mr={4}

        // border={"1px solid red"}
      >
        <Box
          component={"div"}
          sx={{
            position: "relative",
            overflow: "hidden",
          }}
          width={"100%"}
          height={"100%"}
          borderRadius={2}
        >
          <Box
            component={"div"}
            width={"100%"}
            height={"100%"}
            // className="background-image"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              // width: "100%",
              // height: "100%",
              transition: "all .8s ease-in-out",
              // zIndex: -1,
              backgroundImage: `url(${img})`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              // "&:hover": {
              //   scale: "1.1",
              // },
              transform: hovered ? "scale(1.1)" : "scale(1)",
            }}
          />
        </Box>
      </Grid> */}

      <Grid
        item
        xs={12}
        md={6}
        mr={marginRight}
        ml={marginLeft}
        // border="1px solid red"
      >
        <Box
          component={"div"}
          sx={{
            position: "relative",
            overflow: "hidden",
          }}
          width={"100%"}
          height={"100%"}
          borderRadius={2}
        >
          <Box
            sx={{
              position: "relative",
              aspectRatio: "16/9", // 16:9 aspect ratio
              width: "100%", // Set the width to 100%
              backgroundImage: `url(${img})`,
              backgroundSize: "100% 100%",
              // backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              transform: hovered ? "scale(1.1)" : "scale(1)",
              transition: "all .8s ease-in-out",

              // backgroundAttachment: "fixed",
            }}
          />
        </Box>
      </Grid>
    </>
  );
};

const BlogContent = ({ title, content, type, date, author }) => {
  return (
    <>
      <Grid
        item
        xs={12}
        md={6}
        mt={{ xs: 2, md: 0 }}
        // display={"flex"}
        // justifyContent={"center"}
        // border="1px solid red"
      >
        <BlogMainSubItem
          title={title}
          content={content}
          type={type}
          date={date}
          author={author}
        />
      </Grid>
    </>
  );
};

const BlogMainItem = ({ index, img, title, content, type, date, author }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const dateObj = new Date(date);

  const month = dateObj.getMonth() + 1; //months from 1-12
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();

  const newDate = day + "." + month + "." + year;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Grid
        container
        display="flex"
        justifyContent="center"
        spacing={{ md: 5 }}
        alignItems={"center"}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          cursor: "pointer",
        }}
        // border="1px solid red"
      >
        {matches ? (
          index % 2 === 0 ? (
            <>
              <BlogImage
                img={img}
                hovered={hovered}
                // marginRight={5}
                // marginLeft={5}
              />
              <BlogContent
                title={title}
                content={content}
                type={type}
                date={newDate}
                author={author}
              />
            </>
          ) : (
            <>
              <BlogContent
                title={title}
                content={content}
                type={type}
                date={newDate}
                author={author}
              />
              <BlogImage
                img={img}
                hovered={hovered}
                // marginRight={5}
                // marginLeft={5}
              />
            </>
          )
        ) : (
          <>
            <BlogImage
              img={img}
              hovered={hovered}
              // marginRight={5}
              // marginLeft={5}
            />
            <BlogContent
              title={title}
              content={content}
              type={type}
              date={newDate}
              author={author}
            />
          </>
        )}
      </Grid>
    </>
  );
};

const BlogMain = () => {
  // const [page, setPage] = useState(1);
  let navigate = useNavigate();
  const { blogPosts, totalPages, currentPage, setCurrentPage, loading } =
    useContext(BlogContext);

  const items = blogPosts;

  const handleChange = (_, value) => {
    setCurrentPage(value);
  };

  const handleNavigate = (id) => {
    navigate(`/blog/${id}`);
  };

  return (
    <>
      {loading ? (
        <Box
          component={"div"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100vw"}
          height={"65vh"}
          // border="1px solid red"
        >
          <img src={Logo} alt="3D Logo" style={{ maxWidth: "60vw" }} />
        </Box>
      ) : (
        <Grid
          container
          justifyContent={"center"}
          // spacing={2}
          // height={"50%"}
          // border={"1px solid red"}
        >
          {items.map(
            (
              { _id, image_url, title, content, topic, date, writer },
              index
            ) => {
              return (
                <Grid
                  key={_id}
                  item
                  xs={12}
                  mb={12.5}
                  onClick={() => handleNavigate(_id)}
                >
                  <BlogMainItem
                    index={index}
                    img={image_url}
                    title={title}
                    content={content}
                    type={topic}
                    date={date}
                    author={writer}
                  />
                </Grid>
              );
            }
          )}
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            // sx={{ mt: -5 }}
          >
            <Pagination
              count={totalPages}
              page={currentPage}
              // siblingCount={0}
              boundaryCount={1}
              onChange={handleChange}
              variant="outlined"
              shape="rounded"
              color="primary"
              size="large"
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "#fff !important", // Foreground color
                  backgroundColor: "transparent",
                  borderColor: "#2c33b3a4", // Background color
                  "&.Mui-selected": {
                    backgroundColor: "#2c33b373",
                    borderColor: "#2c33b3", // Active color
                  },
                  "&:hover": {
                    backgroundColor: "#2c33b373", // Hover color
                  },
                  "&:active": {
                    backgroundColor: "#2c33b373",
                  },
                },
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default BlogMain;
