import { Box, Grid, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import Menu from "../components/menu/Menu";

const MenuPage = () => {
  let navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          backgroundColor: "black",
          color: "white",
          minHeight: "100vh",
        }}
      >
        <Grid container display="flex" justifyContent="center">
          <Grid
            item
            xs={8.4}
            display="flex"
            justifyContent="end"
            alignItems="center"
            mt={4}
            sx={{
              cursor: "pointer",
              userSelect: "none",
            }}
            onClick={() => navigate(-1)}
          >
            <Typography
              // variant="h5"
              // fontWeight={"bold"}
              fontSize={{
                xs: "0.9em",
                sm: "0.9em",
                md: "1em",
                lg: "1.5em",
              }}
            >
              Close
            </Typography>
            <CloseIcon
              sx={{
                ml: { sm: 0.5, md: 1 },
                width: {
                  xs: "0.8em",
                  sm: "0.8em",
                  md: "0.8em",
                  lg: "1em",
                },
              }}
            />
          </Grid>
          <Menu />
        </Grid>
      </Box>
    </>
  );
};

export default MenuPage;
