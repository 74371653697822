import SwipeableCarousel from "../swipeable-carousel/SwipeableCarousel";

const Services = () => {
  return (
    <>
      <SwipeableCarousel />
    </>
  );
};

export default Services;
