import { Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Instagram, Facebook, LinkedIn } from "@mui/icons-material";

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.1,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const items = [
  { id: 0, text: "Home" },
  { id: 1, text: "Lopende Projecten " },
  { id: 2, text: "Diensten" },
  { id: 3, text: "Over Ons" },
  { id: 4, text: "Blog" },
  { id: 5, text: "Contact" },
];

const MenuItems = ({ id, text }) => {
  let navigate = useNavigate();
  const [color, setColor] = useState("white");
  const handleMouseEnter = () => {
    setColor("#2c32b3");
  };

  const handleMouseLeave = () => {
    setColor("white");
  };
  return (
    <>
      <Grid item xs={12} sm={4.25} pl={{ xs: 2 }}>
        <motion.div variants={item}>
          <Grid container>
            <Grid
              item
              display={"flex"}
              alignItems={"center"}
              justifyContent="center"
            >
              <Typography
                variant="subtitle2"
                component={"span"}
                sx={{
                  fontSize: {
                    xs: "15px",
                    sm: "15px",
                    md: "18px",
                    lg: "28px",
                  },
                  color: color,
                  opacity: 0.5,
                  userSelect: "none",
                }}
                fontWeight={"bold"}
              >
                {"0" + (id + 1)}
              </Typography>
            </Grid>
            <Grid
              item
              display={"flex"}
              alignItems={"center"}
              justifyContent="center"
              onClick={() => navigate(`/${text.toLowerCase()}`)}
            >
              <motion.span
                whileHover={{ scale: 1.075 }}
                whileTap={{ scale: 0.9 }}
                transition={{
                  type: "spring",
                  stiffness: 400,
                  damping: 17,
                }}
              >
                <Typography
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  component={"span"}
                  sx={{
                    ml: 3,
                    fontSize: {
                      xs: "20px",
                      sm: "14px",
                      md: "24px",
                      lg: "32px",
                    },
                    color: color,
                    cursor: "pointer",
                    userSelect: "none",
                  }}
                >
                  {text}
                </Typography>
              </motion.span>
            </Grid>
          </Grid>
        </motion.div>
      </Grid>
    </>
  );
};

const Menu = () => {
  const socialItems = [
    {
      id: 1,
      component: (
        <a
          href={"https://www.instagram.com/blueprintdesigns.nl"}
          style={{ color: "inherit" }}
        >
          <Instagram sx={{ fontSize: { xs: 15, sm: 20, md: 25, lg: 30 } }} />
        </a>
      ),
    },
    {
      id: 2,
      component: (
        <a
          href={"https://www.facebook.com/profile.php?id=100089482491808"}
          style={{ color: "inherit" }}
        >
          <Facebook
            sx={{ fontSize: { xs: 15, sm: 20, md: 25, lg: 30 } }}
            href={""}
          />
        </a>
      ),
    },
    {
      id: 3,
      component: (
        <a
          href={"https://www.linkedin.com/company/blueprintdesigns/ "}
          style={{ color: "inherit" }}
        >
          <LinkedIn
            sx={{ fontSize: { xs: 15, sm: 20, md: 25, lg: 30 } }}
            href={""}
          />
        </a>
      ),
    },
  ];
  return (
    <>
      <Grid
        container
        item
        xs={10}
        display="flex"
        justifyContent={"center"}
        // border="1px solid red"
      >
        <motion.div
          className="container"
          variants={container}
          initial="hidden"
          animate="visible"
        >
          <Grid
            // border="1px solid red"
            container
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            mt={15}
            // pl="16vw"
            // pt="15vh"
          >
            {items.map(({ id, text }) => {
              return <MenuItems key={id} id={id} text={text} />;
            })}
          </Grid>
          <motion.div variants={item}>
            <Grid
              container
              item
              // xs={8}
              // sm={4}
              display={"flex"}
              justifyContent={"center"}
              mt={5}
              // border={"1px solid red"}
            >
              {socialItems.map((item) => (
                <Grid
                  item
                  key={item.id}
                  xs={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {item.component}
                </Grid>
              ))}
            </Grid>
          </motion.div>
        </motion.div>
      </Grid>
    </>
  );
};

export default Menu;
