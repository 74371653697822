import { Box, Grid, Typography } from "@mui/material";
import logo from "../assets/isolated logo.png";
import Calendly from "../components/calendly/Calendly";
import DescriptionDisplay from "../components/description-display/DescriptionDisplay";
import { useNavigate } from "react-router-dom";

const CalendlyPage = () => {
  let navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          backgroundColor: "black",
          color: "white",
          minHeight: "100vh",
        }}
      >
        <Grid container display="flex" justifyContent="center">
          <Grid
            item
            xs={10}
            display="flex"
            justifyContent="center"
            sx={{ mt: 5 }}
          >
            <Grid container>
              <Grid
                item
                xs={2}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                // border={"1px solid red"}
              >
                <Box
                  onClick={() => navigate("/home")}
                  component={"img"}
                  src={logo}
                  alt={"blue print logo"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: { xs: "4vh", sm: "6vh", md: "7vh", lg: "10vh" },
                    cursor: "pointer",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={10}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                // border={"1px solid red"}
              >
                <Typography
                  variant="h2"
                  fontWeight={"bold"}
                  fontSize={{ xs: "1.5rem", sm: "2.5rem", md: "3.5rem" }}
                  sx={{ color: "#2c32b3" }}
                >
                  BLUEPRINTDESIGNS
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={10}
            display="flex"
            justifyContent="center"
            sx={{ mt: 5 }}
          >
            <Grid
              container
              spacing={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item xs={12} md={8}>
                <Grid container>
                  <Grid item xs={12}>
                    <DescriptionDisplay />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      color={"gray"}
                      variant="h5"
                      fontSize={{ xs: "1rem", sm: "1.25 rem", md: "1.5rem" }}
                      textAlign={"center"}
                      sx={{
                        textJustify: "inter-word",
                      }}
                    >
                      Hey! Wij zijn BLUEPRINT en staan klaar voor jou om jouw
                      digitale dromen te realiseren. Wil jij het optimale halen
                      uit jouw bedrijf? Plan dan een vrijblijvend gesprek in met
                      een van onze accountmanagers.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Calendly />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={10} mt={5} display={"flex"} justifyContent={"center"}>
            <Typography
              variant={"h2"}
              fontSize={{ xs: "1rem", sm: "2.5rem", md: "3.5rem" }}
            >
              Plan nu je gesprek in!
            </Typography>
          </Grid>
          <Grid xs={10} item mt={3}></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CalendlyPage;
